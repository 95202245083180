// src/components/PlayerBatchForm.tsx

import React, { useState } from 'react';
import { Player } from '../models/Player';
import { v4 as uuidv4 } from 'uuid';
import { dbPromise } from '../db';

interface PlayerBatchFormProps {
  addPlayers: (players: Player[]) => void;
}

const PlayerBatchForm: React.FC<PlayerBatchFormProps> = ({ addPlayers }) => {
  const [playerText, setPlayerText] = useState('');

  const handleAddPlayers = async () => {
    const lines = playerText.split('\n');
    const newPlayers: Player[] = [];

    lines.forEach((line) => {
      const playerName = line.split('-')[1]?.trim();
      if (playerName) {
        newPlayers.push({
          id: uuidv4(),
          name: playerName,
          performance: 'casual',
        });
      }
    });

    const db = await dbPromise;
    await Promise.all(newPlayers.map((player) => db.put('players', player)));

    addPlayers(newPlayers);
    setPlayerText('');
  };

  return (
    <div className="mt-4">
      <textarea
        rows={10}
        placeholder="Insira a lista de jogadores aqui..."
        value={playerText}
        onChange={(e) => setPlayerText(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
      />
      <button
        onClick={handleAddPlayers}
        className="w-full px-4 py-2 mt-2 font-bold text-white bg-primary rounded hover:bg-secondary"
      >
        Adicionar Jogadores
      </button>
    </div>
  );
};

export default PlayerBatchForm;
