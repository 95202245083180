import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { Player } from '../models/Player';
import { dbPromise } from '../db';

interface TeamListProps {
  teams: { id: string; players: Player[] }[];
  setTeams: (teams: { id: string; players: Player[] }[]) => void;
}

const TeamList: React.FC<TeamListProps> = ({
  teams = [],
  setTeams = () => {},
}) => {
  const onDragEnd = async (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const updatedTeams = [...teams];

    const sourceTeam = updatedTeams[source.droppableId];
    const destTeam = updatedTeams[destination.droppableId];

    const [movedPlayer] = sourceTeam.players.splice(source.index, 1);
    destTeam.players.splice(destination.index, 0, movedPlayer);

    setTeams(updatedTeams);

    const db = await dbPromise;
    await db.clear('teams');
    for (const team of teams) {
      await db.put('teams', team);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-teams" direction="vertical">
        {(provided: DroppableProvided) => (
          <div
            className="w-full p-4 mt-4 bg-white rounded-xl"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <h2 className="mb-4 text-xl font-bold text-gray-800">
              Lista de Times
            </h2>
            {teams.length === 0 ? (
              <p className="text-gray-600">Nenhum time gerado.</p>
            ) : (
              teams.map((team, teamIndex) => (
                <Droppable
                  key={teamIndex}
                  droppableId={teamIndex.toString()}
                  direction="vertical"
                >
                  {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                  ) => (
                    <div
                      className={`p-2 mb-2 bg-gray-100 rounded transition-all duration-300 ease-in-out ${snapshot.isDraggingOver ? 'bg-gray-200' : ''}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <h3 className="text-lg font-bold text-gray-700">
                        Time {team.id.replace('team', '')}
                      </h3>
                      <ul className="list-decimal list-inside">
                        {team.players.map((player, playerIndex) => (
                          <Draggable
                            key={player.name}
                            draggableId={player.name}
                            index={playerIndex}
                          >
                            {(
                              provided: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => (
                              <li
                                className={`capitalize text-gray-600 p-1 cursor-pointer transition-transform duration-300 ease-in-out rounded ${snapshot.isDragging ? 'bg-secondary text-white shadow-xl' : ''}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {player.name}
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    </div>
                  )}
                </Droppable>
              ))
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TeamList;
