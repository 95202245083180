import React, { useEffect, useState } from 'react';
import { Users, Gear, Timer } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { getOSClass, OS } from '../utils/OS';

const Navbar: React.FC = () => {
  const [osClass, setOsClass] = useState<OS>(OS.Unknown);

  useEffect(() => {
    const fetchOSClass = async () => {
      const os = await getOSClass();
      setOsClass(os);
    };

    fetchOSClass();
  }, []);

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl shadow-xl z-50">
      <div
        className={`flex justify-around items-center p-4 ${osClass === OS.iOS ? 'pb-8' : ''}`}
      >
        <Link
          to="/"
          className="flex flex-col items-center text-primary hover:text-secondary"
        >
          <Users
            size={24}
            weight="duotone"
            className="hover:opacity-75 transition-opacity duration-200"
          />
          <span className="text-xs">Jogadores</span>
        </Link>
        <Link
          to="/teams"
          className="flex flex-col items-center text-primary hover:text-secondary"
        >
          <Gear
            size={24}
            weight="duotone"
            className="hover:opacity-75 transition-opacity duration-200"
          />
          <span className="text-xs">Times</span>
        </Link>
        <Link
          to="/timer"
          className="flex flex-col items-center text-primary hover:text-secondary"
        >
          <Timer
            size={24}
            weight="duotone"
            className="hover:opacity-75 transition-opacity duration-200"
          />
          <span className="text-xs">Contador</span>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
