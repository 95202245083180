import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import PlayersPage from './pages/PlayerPage';
import TeamsPage from './pages/TeamPage';
import { TitleProvider, useTitle } from './contexts/TitleContext';
import Navbar from './components/Navbar';
import { PageProvider } from './contexts/PageContext';
import TimerPage from './pages/TimerPage';

const App: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (query: string) => {
    setSearchTerm(query);
  };

  return (
    <TitleProvider>
      <PageProvider>
        <Router>
          <div className="flex flex-col bg-[#f5f6fa] h-full min-h-screen">
            <Header onSearch={handleSearch} />
            <Navbar />
            <Routes>
              <Route
                path="/"
                element={
                  <PageWithTitle title="Jogadores">
                    <PlayersPage searchTerm={searchTerm} />
                  </PageWithTitle>
                }
              />
              <Route
                path="/teams"
                element={
                  <PageWithTitle title="Times">
                    <TeamsPage searchTerm={searchTerm} />
                  </PageWithTitle>
                }
              />
              <Route
                path="/timer"
                element={
                  <PageWithTitle title="Timer">
                    <TimerPage />
                  </PageWithTitle>
                }
              />
            </Routes>
          </div>
        </Router>
      </PageProvider>
    </TitleProvider>
  );
};

const PageWithTitle: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => {
  const { setTitle } = useTitle();

  React.useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  return <main className="flex-1 px-8 pt-6 pb-24">{children}</main>;
};

export default App;
