import React, { useEffect, useState } from 'react';
import TeamGenerator from '../components/TeamGenerator';
import TeamList from '../components/TeamList';
import { Player } from '../models/Player';
import { dbPromise } from '../db';
import { useTitle } from '../contexts/TitleContext';

interface TeamPageProps {
  searchTerm: string;
}

const TeamPage: React.FC<TeamPageProps> = ({ searchTerm }) => {
  const { title } = useTitle();
  const [players, setPlayers] = useState<Player[]>([]);
  const [teams, setTeams] = useState<{ id: string; players: Player[] }[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<
    { id: string; players: Player[] }[]
  >([]);

  useEffect(() => {
    const loadData = async () => {
      const db = await dbPromise;
      const allPlayers = await db.getAll('players');
      setPlayers(allPlayers);

      const allTeams = await db.getAll('teams');
      setTeams(allTeams);
    };

    loadData();
  }, []);

  useEffect(() => {
    const filtered = teams.filter(
      (team) =>
        team.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        team.players.some((player) =>
          player.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    setFilteredTeams(filtered);
  }, [searchTerm, teams]);

  const handleTeamsGenerated = async (
    newTeams: { id: string; players: Player[] }[]
  ) => {
    const db = await dbPromise;
    await db.clear('teams');
    newTeams.forEach(async (team) => {
      await db.put('teams', team);
    });

    setTeams(newTeams);
  };

  return (
    <div className="flex flex-col">
      <h1 className="text-3xl font-bold">{title}</h1>
      <TeamGenerator
        players={players}
        onTeamsGenerated={handleTeamsGenerated}
      />
      <TeamList teams={filteredTeams} setTeams={setTeams} />
    </div>
  );
};

export default TeamPage;
