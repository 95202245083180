import { Device } from '@capacitor/device';

export enum OS {
  Android = 'android',
  iOS = 'ios',
  Web = 'web',
  Unknown = 'unknown',
}

export const getOSClass = async (): Promise<OS> => {
  try {
    const info = await Device.getInfo();

    if (info.platform === 'android') {
      return OS.Android;
    }
    if (info.platform === 'ios') {
      return OS.iOS;
    }
    if (info.platform === 'web') {
      return OS.Web;
    }
  } catch (error) {
    console.error('Erro ao obter informações do dispositivo:', error);
  }

  return OS.Unknown;
};
