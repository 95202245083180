import {
  ClockClockwise,
  Minus,
  Pause,
  Play,
  Plus,
} from '@phosphor-icons/react';
import React, { useState, useEffect, useRef } from 'react';

// Define o código do Web Worker como uma string
const workerScript = `
  let timerId;
  let timeLeft = 0;

  onmessage = (e) => {
    const { command, time } = e.data;

    if (command === 'start') {
      timeLeft = time;
      timerId = setInterval(() => {
        timeLeft--;
        postMessage(timeLeft);
        if (timeLeft <= 0) {
          clearInterval(timerId);
          postMessage('finished');
        }
      }, 1000);
    } else if (command === 'stop') {
      clearInterval(timerId);
    } else if (command === 'reset') {
      clearInterval(timerId);
      timeLeft = time;
      postMessage(timeLeft);
    }
  };
`;

// Função para criar o Web Worker usando Blob URL
const createWorker = () => {
  const blob = new Blob([workerScript], { type: 'application/javascript' });
  return new Worker(URL.createObjectURL(blob));
};

const TimerPage: React.FC = () => {
  const [timeInMinutes, setTimeInMinutes] = useState<number>(8); // Default de 8 minutos
  const [timeLeft, setTimeLeft] = useState<number>(timeInMinutes * 60); // Tempo em segundos
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [hasEnded, setHasEnded] = useState<boolean>(false);
  const workerRef = useRef<Worker | null>(null);

  // Iniciar o Timer
  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      setHasEnded(false);
      workerRef.current?.postMessage({ command: 'start', time: timeLeft });
    }
  };

  // Parar o Timer
  const stopTimer = () => {
    if (isRunning) {
      setIsRunning(false);
      workerRef.current?.postMessage({ command: 'stop' });
    }
  };

  // Reiniciar o Timer
  const resetTimer = () => {
    stopTimer();
    setTimeLeft(timeInMinutes * 60);
    setHasEnded(false);
    workerRef.current?.postMessage({
      command: 'reset',
      time: timeInMinutes * 60,
    });
  };

  // Atualizar tempo em minutos
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseInt(e.target.value, 10);
    setTimeInMinutes(newTime);
    setTimeLeft(newTime * 60);
  };

  // Função para formatar o tempo em minutos e segundos
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Emitir som de apito quando o tempo acabar
  const playWhistle = () => {
    const audio = new Audio('/assets/whistle.mp3'); // Caminho do som de apito
    audio.play();
  };

  // Restaura o estado do timer ao voltar do background
  useEffect(() => {
    // Cria e inicializa o Web Worker
    workerRef.current = createWorker();

    workerRef.current.onmessage = (e) => {
      if (e.data === 'finished') {
        setIsRunning(false);
        setHasEnded(true);
        playWhistle();
      } else {
        setTimeLeft(e.data);
      }
    };

    // Limpeza do Web Worker
    /* return () => {
      if (workerRef.current) {
        URL.revokeObjectURL(workerRef.current.url); // Revoga o URL Blob
        workerRef.current.terminate(); // Termina o worker
      }
    }; */
  }, []);

  return (
    <div className="flex flex-col h-[calc(100vh - 250px)]">
      <h1 className="text-3xl font-bold">Timer</h1>

      <div className="flex justify-center items-center my-4">
        <span className="text-white text-7xl font-bold font-mono p-4 bg-secondary rounded-xl">
          {formatTime(timeLeft)}
        </span>
      </div>

      <div className="mt-auto">
        <div className="flex items-center border border-gray-300 rounded my-4">
          <button
            onClick={() => {
              setTimeInMinutes((prev) => Math.max(prev - 1, 1));
              setTimeLeft((prev) => Math.max(prev - 1 * 60, 1));
            }}
            className={`p-2 text-gray-600 hover:bg-gray-200 rounded-l ${timeLeft == 60 ? 'cursor-not-allowed' : ''}`}
            disabled={timeLeft == 60}
          >
            <Minus size={24} />
          </button>
          <input
            type="number"
            min="1"
            value={timeInMinutes}
            onChange={handleTimeChange}
            className="w-full p-2 text-center border-none"
          />
          <button
            onClick={() => {
              setTimeInMinutes((prev) => prev + 1);
              setTimeLeft((prev) => prev + 1 * 60);
            }}
            className="p-2 text-gray-600 hover:bg-gray-200 rounded-r"
          >
            <Plus size={24} />
          </button>
        </div>

        <div className="flex justify-center gap-4 flex-wrap">
          <button
            onClick={startTimer}
            className="flex justify-center p-2 bg-green-500 text-white rounded flex-1 disabled:opacity-50"
            disabled={isRunning}
          >
            <Play
              size={24}
              weight="duotone"
              className="hover:opacity-75 transition-opacity duration-200"
            />
          </button>
          <button
            onClick={stopTimer}
            className="flex justify-center p-2 bg-yellow-500 text-white rounded flex-1 disabled:opacity-50"
            disabled={!isRunning}
          >
            <Pause
              size={24}
              weight="duotone"
              className="hover:opacity-75 transition-opacity duration-200"
            />
          </button>
          <button
            onClick={resetTimer}
            className="flex justify-center p-2 bg-red-500 text-white rounded w-full"
          >
            <ClockClockwise
              size={24}
              weight="duotone"
              className="hover:opacity-75 transition-opacity duration-200"
            />
          </button>
        </div>
      </div>

      {hasEnded && (
        <div className="text-red-500 font-bold mt-4">
          A partida terminou! ⏰
        </div>
      )}
    </div>
  );
};

export default TimerPage;
