// src/pages/PlayerPage.tsx

import React, { useState, useEffect } from 'react';
import PlayerForm from '../components/PlayerForm';
import PlayerBatchForm from '../components/PlayerBatchForm';
import PlayerList from '../components/PlayerList';
import { Player } from '../models/Player';
import { dbPromise } from '../db';
import { useTitle } from '../contexts/TitleContext';
import { Plus, Trash, X } from '@phosphor-icons/react'; // Importar ícones

interface PlayerPageProps {
  searchTerm: string;
}

const PlayerPage: React.FC<PlayerPageProps> = ({ searchTerm }) => {
  const { title } = useTitle();
  const [players, setPlayers] = useState<Player[]>([]);
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showBatchForm, setShowBatchForm] = useState<boolean>(false);

  useEffect(() => {
    const loadPlayers = async () => {
      const db = await dbPromise;
      const allPlayers = await db.getAll('players');
      setPlayers(allPlayers);
      setFilteredPlayers(allPlayers);
    };

    loadPlayers();
  }, []);

  useEffect(() => {
    setFilteredPlayers(
      players.filter((player) =>
        player.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, players]);

  const addPlayer = (player: Player) => {
    setPlayers([...players, player]);
  };

  const addPlayers = (newPlayers: Player[]) => {
    setPlayers((prevPlayers) => [...prevPlayers, ...newPlayers]);
  };

  const clearDatabase = async () => {
    const db = await dbPromise;
    await db.clear('players');
    await db.clear('teams');
    setPlayers([]);
    setFilteredPlayers([]);
    alert('Banco de dados limpo com sucesso!');
  };

  return (
    <div className="flex flex-col">
      <h1 className="text-3xl font-bold mb-8">{title}</h1>

      <div className="flex items-center space-x-4 mb-4 w-full">
        <button
          onClick={() => setShowForm(!showForm)}
          className="w-full flex items-center px-4 py-2 text-white bg-secondary rounded hover:bg-primary"
        >
          {showForm ? <X size={24} /> : <Plus size={24} />}
          <span className="ml-2">{showForm ? 'Fechar' : 'Jogador'}</span>
        </button>

        <button
          onClick={() => setShowBatchForm(!showBatchForm)}
          className="w-full flex items-center px-4 py-2 text-white bg-secondary rounded hover:bg-primary"
        >
          {showBatchForm ? <X size={24} /> : <Plus size={24} />}
          <span className="ml-2">{showBatchForm ? 'Fechar' : 'Lista'}</span>
        </button>
      </div>

      {showForm && <PlayerForm addPlayer={addPlayer} />}
      {showBatchForm && <PlayerBatchForm addPlayers={addPlayers} />}
      <PlayerList players={filteredPlayers} setPlayers={setPlayers} />

      <button
        onClick={clearDatabase}
        className="flex items-center px-4 py-2 mt-8 text-white bg-secondary rounded hover:bg-red-700"
      >
        <Trash size={24} />
        <span className="ml-2">Limpar Banco de Dados</span>
      </button>
    </div>
  );
};

export default PlayerPage;
