import React, { useState } from 'react';
import { Player } from '../models/Player';
import { dbPromise } from '../db';

interface PlayerFormProps {
  addPlayer: (player: Player) => void;
}

const PlayerForm: React.FC<PlayerFormProps> = ({ addPlayer }) => {
  const [name, setName] = useState('');
  const [performance, setPerformance] = useState<
    'principiante' | 'casual' | 'peladeiro'
  >('casual');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newPlayer: Player = {
      id: String(Math.random()),
      name,
      performance,
    };

    const db = await dbPromise;
    await db.put('players', newPlayer);

    addPlayer(newPlayer);
    setName('');
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center gap-4 p-4 bg-white rounded-lg"
    >
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nome do jogador"
        required
        className="p-2 border border-gray-300 rounded w-full"
      />
      <select
        value={performance}
        onChange={(e) =>
          setPerformance(
            e.target.value as 'principiante' | 'casual' | 'peladeiro'
          )
        }
        className="p-2 border border-gray-300 rounded w-full"
      >
        <option value="novato">Novato</option>
        <option value="bom">Bom</option>
        <option value="peladeiro">Peladeiro</option>
      </select>
      <button
        type="submit"
        className="w-full px-4 py-2 font-semibold text-white bg-primary rounded-lg hover:bg-secondary"
      >
        Adicionar Jogador
      </button>
    </form>
  );
};

export default PlayerForm;
