import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PageContextProps {
  currentPage: 'players' | 'teams';
  setCurrentPage: React.Dispatch<React.SetStateAction<'players' | 'teams'>>;
}

const PageContext = createContext<PageContextProps | undefined>(undefined);

export const PageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<'players' | 'teams'>(
    'players'
  );

  return (
    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePageContext must be used within a PageProvider');
  }
  return context;
};
