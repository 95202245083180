import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import { usePageContext } from '../contexts/PageContext';

interface HeaderProps {
  onSearch: (query: string) => void;
}

const Header: React.FC<HeaderProps> = ({ onSearch }) => {
  const { currentPage } = usePageContext();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  return (
    <header className="flex bg-white text-primary p-8 rounded-b-3xl items-center">
      <img src={logo} alt="Logo" className="h-12 self-start" />
      <div className="flex-1 ml-4">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={
            currentPage === 'players'
              ? 'Buscar jogadores...'
              : 'Buscar times e jogadores...'
          }
          className="w-full p-2 rounded-lg bg-slate-200"
        />
      </div>
    </header>
  );
};

export default Header;
