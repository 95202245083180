import React, { useState } from 'react';
import { Player } from '../models/Player';
import { ArrowsCounterClockwise, Minus, Plus } from '@phosphor-icons/react';
import { dbPromise } from '../db';

const performanceRanking = {
  principiante: 1,
  casual: 2,
  peladeiro: 3,
};

interface TeamGeneratorProps {
  players: Player[];
  onTeamsGenerated: (teams: { id: string; players: Player[] }[]) => void;
}

const TeamGenerator: React.FC<TeamGeneratorProps> = ({
  players,
  onTeamsGenerated,
}) => {
  const [numTeams, setNumTeams] = useState(2);
  const [maxPlayersPerTeam, setMaxPlayersPerTeam] = useState(5);

  const handleGenerateTeams = async () => {
    const sortedPlayers = [...players].sort(
      (a, b) =>
        performanceRanking[b.performance] - performanceRanking[a.performance]
    );

    const teams: { id: string; players: Player[]; totalPerformance: number }[] =
      [];

    // Inicialmente cria os times vazios até a quantidade de times especificada
    for (let i = 0; i < numTeams; i++) {
      teams.push({ id: `team${i + 1}`, players: [], totalPerformance: 0 });
    }

    // Atribui o melhor jogador disponível a cada time para garantir balanceamento inicial
    let currentTeamIndex = 0;
    while (
      sortedPlayers.length > 0 &&
      teams.some((team) => team.players.length < maxPlayersPerTeam)
    ) {
      const playerToAssign = sortedPlayers.shift() as Player;

      // Verifica se o time atual tem espaço para mais jogadores
      if (teams[currentTeamIndex].players.length < maxPlayersPerTeam) {
        teams[currentTeamIndex].players.push(playerToAssign);
        teams[currentTeamIndex].totalPerformance +=
          performanceRanking[playerToAssign.performance];
      }

      // Move para o próximo time
      currentTeamIndex = (currentTeamIndex + 1) % numTeams;
    }

    // Se ainda houver jogadores sobrando após preencher os times, cria novos times
    while (sortedPlayers.length > 0) {
      const newTeamId = `team${teams.length + 1}`;
      const newTeamPlayers: Player[] = [];

      for (let i = 0; i < maxPlayersPerTeam && sortedPlayers.length > 0; i++) {
        const playerToAssign = sortedPlayers.shift() as Player;
        newTeamPlayers.push(playerToAssign);
      }

      teams.push({
        id: newTeamId,
        players: newTeamPlayers,
        totalPerformance: newTeamPlayers.reduce(
          (acc, player) => acc + performanceRanking[player.performance],
          0
        ),
      });
    }

    // Salvar os times no IndexedDB e atualizar o estado
    const db = await dbPromise;
    await db.clear('teams');
    for (const team of teams) {
      await db.put('teams', team);
    }

    onTeamsGenerated(teams);
  };

  return (
    <div className="mt-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700">
            Número de Times
          </label>
          <div className="flex items-center border border-gray-300 rounded">
            <button
              onClick={() => setNumTeams((prev) => Math.max(prev - 1, 2))}
              className="p-2 text-gray-600 hover:bg-gray-200 rounded-l"
            >
              <Minus size={24} />
            </button>
            <input
              type="number"
              min="2"
              value={numTeams}
              onChange={(e) => setNumTeams(parseInt(e.target.value))}
              className="w-full p-2 text-center border-none"
            />
            <button
              onClick={() => setNumTeams((prev) => prev + 1)}
              className="p-2 text-gray-600 hover:bg-gray-200 rounded-r"
            >
              <Plus size={24} />
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700">
            Máx Jogadores
          </label>
          <div className="flex items-center border border-gray-300 rounded">
            <button
              onClick={() =>
                setMaxPlayersPerTeam((prev) => Math.max(prev - 1, 1))
              }
              className="p-2 text-gray-600 hover:bg-gray-200 rounded-l"
            >
              <Minus size={24} />
            </button>
            <input
              type="number"
              min="1"
              value={maxPlayersPerTeam}
              onChange={(e) => setMaxPlayersPerTeam(parseInt(e.target.value))}
              className="w-full p-2 text-center border-none"
            />
            <button
              onClick={() => setMaxPlayersPerTeam((prev) => prev + 1)}
              className="p-2 text-gray-600 hover:bg-gray-200 rounded-r"
            >
              <Plus size={24} />
            </button>
          </div>
        </div>
        <button
          onClick={handleGenerateTeams}
          className="flex justify-center items-center px-4 py-2 font-bold text-white bg-secondary rounded-lg hover:bg-primary"
        >
          <ArrowsCounterClockwise size={24} />
        </button>
      </div>
    </div>
  );
};

export default TeamGenerator;
