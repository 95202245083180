import { openDB } from 'idb';

const DB_NAME = 'FootballAppDB';
const DB_VERSION = 1;

export const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('players')) {
      db.createObjectStore('players', { keyPath: 'id' });
    }

    if (!db.objectStoreNames.contains('teams')) {
      db.createObjectStore('teams', { keyPath: 'id' });
    }
  },
});
