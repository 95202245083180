// src/components/PlayerList.tsx

import React, { useState } from 'react';
import { Player } from '../models/Player';
import { dbPromise } from '../db';
import { PencilSimple, Trash } from '@phosphor-icons/react';

interface PlayerListProps {
  players: Player[];
  setPlayers: (players: Player[]) => void;
}

const PlayerList: React.FC<PlayerListProps> = ({ players, setPlayers }) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editName, setEditName] = useState('');
  const [editPerformance, setEditPerformance] = useState<
    'principiante' | 'casual' | 'peladeiro'
  >('casual');

  const handleEdit = (index: number) => {
    setEditIndex(index);
    setEditName(players[index].name);
    setEditPerformance(players[index].performance);
  };

  const handleSave = async (index: number) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].name = editName;
    updatedPlayers[index].performance = editPerformance;

    const db = await dbPromise;
    await db.put('players', updatedPlayers[index]);

    setPlayers(updatedPlayers); // Atualize a lista
    setEditIndex(null);
  };

  const handleDelete = async (id: string) => {
    const updatedPlayers = players.filter((player) => player.id !== id);

    const db = await dbPromise;
    await db.delete('players', id);

    setPlayers(updatedPlayers);
  };

  return (
    <div className="mt-4">
      <h2 className="text-2xl font-bold text-primary">Lista de Jogadores</h2>
      <ul className="mt-2">
        {players.length === 0 ? (
          <p className="text-gray-600">Nenhum jogador cadastrado.</p>
        ) : (
          players.map((player, index) => (
            <li key={player.id} className="p-2 border border-b-gray-200">
              {editIndex === index ? (
                <>
                  <div className="flex w-full space-x-2">
                    <input
                      type="text"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      className="w-full p-2 rounded bg-white"
                    />
                    <select
                      value={editPerformance}
                      onChange={(e) =>
                        setEditPerformance(
                          e.target.value as
                            | 'principiante'
                            | 'casual'
                            | 'peladeiro'
                        )
                      }
                      className="p-2 rounded bg-white"
                    >
                      <option value="principiante">Principiante</option>
                      <option value="casual">Casual</option>
                      <option value="peladeiro">Peladeiro</option>
                    </select>
                  </div>
                  <div className="flex w-full space-x-4 mt-2">
                    <button
                      onClick={() => handleSave(index)}
                      className="w-full px-2 py-1 font-bold text-white bg-secondary rounded hover:bg-blue-700"
                    >
                      Salvar
                    </button>
                    <button
                      onClick={() => setEditIndex(null)}
                      className="w-full px-2 py-1 font-bold text-white bg-primary rounded hover:bg-gray-700"
                    >
                      Cancelar
                    </button>
                  </div>
                </>
              ) : (
                <div className="flex justify-between w-full">
                  <div>
                    {index + 1}. {player.name} ({player.performance})
                  </div>
                  <div>
                    <button
                      onClick={() => handleEdit(index)}
                      className="px-2 py-1 ml-2 font-bold text-white bg-yellow-500 rounded hover:bg-yellow-700"
                    >
                      <PencilSimple size={18} weight="duotone" />
                    </button>
                    <button
                      onClick={() => handleDelete(player.id)}
                      className="px-2 py-1 ml-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                    >
                      <Trash size={18} weight="duotone" />
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default PlayerList;
